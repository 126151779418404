// $white: #181818;
$white: #080b28;//#0c0c37
$black: #60bcdc;
$primaryLight: #60bcdc;
$primaryMain: #10566d;
$secondaryMain: #60bcdc;
// success Colors
$successLight: #2dd221;
$success200: #96efc4;
$successMain: #76cea8;
$successDark: #d0ebd6;
// error
$errorLight: #902828;
$errorMain: #f3aaa4;
$errorDark: #f7d8d8;
// orange
$orangeLight: #4d110a;
$orangeMain: #361409;
$orangeDark: #ffd4c6;
// warning
$warningLight: #42350b;
$warningMain: #cba303;
$warningDark: #fcf2d4;
// grey
$grey50: #181818;
$grey100: #292929;
// $grey200: #313131;
$grey200: #10566d;
$grey300: #454444;
$grey500: #60bcdc;
// $grey500: black;
$grey600: #8e8e8e;
$grey700: #d5d7db;
$grey900: #60bcdc;
// ==============================|| DARK THEME VARIANTS ||============================== //
// paper & background
$darkBackground: #6f8cf2; // level 3
$darkPaper: #4c6ee7; // level 4
// dark 800 & 900
$darkLevel1: #6376bb; // level 1
$darkLevel2: #5a6fbd; // level 2
// primary dark
// $darkPrimaryLight: #ededed;
$darkPrimaryLight: #0a0328;
$darkPrimaryMain: #d9ebf9;
$darkPrimaryDark: #aec9e1;
$darkPrimary200: #3984c2;
$darkPrimary800: #cce3fd;
// secondary dark
$darkSecondaryLight: #3b167e;
$darkSecondaryMain: #d9ccff;
$darkSecondaryDark: #decfff;
$darkSecondary200: #231243;
$darkSecondary800: #f9f5ff;
// text variants
$darkTextTitle: #0a1539;
$darkTextPrimary: #14204a;
$darkTextSecondary: #314179;
// ==============================|| JAVASCRIPT ||============================== //
$paper: $white;
// $paper: black;
$card: #334553;
:export {
  // paper & background
  paper: $paper;
  card: $card;
  // primary
  primaryLight: $primaryLight;
  // primary200: $primary200;
  primaryMain: $primaryMain;
  // primaryDark: $primaryDark;
  // primary800: $primary800;

  // // secondary
  // secondaryLight: $secondaryLight;
  // secondary200: $secondary200;
  secondaryMain: $secondaryMain;
  // secondaryDark: $secondaryDark;
  // secondary800: $secondary800;

  // success
  successLight: $successLight;
  success200: $success200;
  successMain: $successMain;
  successDark: $successDark;

  // error
  errorLight: $errorLight;
  errorMain: $errorMain;
  errorDark: $errorDark;

  // orange
  orangeLight: $orangeLight;
  orangeMain: $orangeMain;
  orangeDark: $orangeDark;

  // warning
  warningLight: $warningLight;
  warningMain: $warningMain;
  warningDark: $warningDark;

  // grey
  grey50: $grey50;
  grey100: $grey100;
  grey200: $grey200;
  grey300: $grey300;
  grey500: $grey500;
  grey600: $grey600;
  grey700: $grey700;
  grey900: $grey900;

  // ==============================|| DARK THEME VARIANTS ||============================== //

  // paper & background
  darkPaper: $darkPaper;
  darkBackground: $darkBackground;

  // dark 800 & 900
  darkLevel1: $darkLevel1;
  darkLevel2: $darkLevel2;

  // text variants
  darkTextTitle: $darkTextTitle;
  darkTextPrimary: $darkTextPrimary;
  darkTextSecondary: $darkTextSecondary;

  // primary dark
  darkPrimaryLight: $darkPrimaryLight;
  darkPrimaryMain: $darkPrimaryMain;
  darkPrimaryDark: $darkPrimaryDark;
  darkPrimary200: $darkPrimary200;
  darkPrimary800: $darkPrimary800;

  // secondary dark
  darkSecondaryLight: $darkSecondaryLight;
  darkSecondaryMain: $darkSecondaryMain;
  darkSecondaryDark: $darkSecondaryDark;
  darkSecondary200: $darkSecondary200;
  darkSecondary800: $darkSecondary800;

  white: $white;
  black: $black;
  dark: $black;
}
